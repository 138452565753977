import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

interface TariffDefinition {
    pricePerHour: number;
    priceFlat: number | null;
    maxPriceItem: number | null;
    minPriceItem: number | null;
    timeFrom: string;
    timeTo: string;
    minuteFrom: number;
    minuteTo: number;
    onePerDay: boolean;
}

interface TariffGroup {
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    sunday: boolean;
    holiday: boolean | null;
    maxPrice: number | null;
    activeTariffDefinitions: TariffDefinition[];
}

interface Tariff {
    tariffID: string;
    currentlyActiveAndValid: boolean;
    maxPriceHoliday: number | null;
    activeTariffGroups: TariffGroup[];
}

interface DetailItem {
    string: string;
    price: number;
}

interface DayGrouping {
    days: string[];
    detail: DetailItem[];
}

interface DayRange {
    start: string;
    end: string;
}

export const formatTarrifPricing = (tarrif: Tariff) => {
    let formatted = [];

    const daysInOrder = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

    const dayGroupings: Record<string, DayGrouping> = {};

    tarrif.activeTariffGroups.forEach((tarrifEl) => {
        if (tarrifEl.holiday) return;

        const detail = tarrifEl.activeTariffDefinitions.map((el) => {
            const timeFrom = dayjs(el.timeFrom, "HH:mm:ss").format("HH:mm");
            const timeTo = el.timeTo === "00:00:00" ? "23:59" : dayjs(el.timeTo, "HH:mm:ss").format("HH:mm");

            return {
                string: `${timeFrom}-${timeTo}`,
                price: el.pricePerHour
            };
        });

        const detailKey = JSON.stringify(detail);

        if (!dayGroupings[detailKey]) {
            dayGroupings[detailKey] = {
                days: [],
                detail
            };
        }

        daysInOrder.forEach(day => {
            if (tarrifEl[day as keyof typeof tarrifEl]) {
                dayGroupings[detailKey].days.push(day);
            }
        });
    });

    Object.values(dayGroupings).forEach(group => {
        group.days.sort((a, b) => daysInOrder.indexOf(a) - daysInOrder.indexOf(b));

        let currentRange: DayRange = {
            start: group.days[0],
            end: group.days[0]
        };

        const ranges: DayRange[] = [];

        for (let i = 1; i < group.days.length; i++) {
            const currentDayIndex = daysInOrder.indexOf(group.days[i]);
            const prevDayIndex = daysInOrder.indexOf(group.days[i - 1]);

            if (currentDayIndex - prevDayIndex === 1) {
                currentRange.end = group.days[i];
            } else {
                ranges.push({ ...currentRange });
                currentRange = {
                    start: group.days[i],
                    end: group.days[i]
                };
            }
        }
        ranges.push(currentRange);

        ranges.forEach(range => {
            formatted.push({
                firstDay: range.start,
                lastDay: range.start !== range.end ? range.end : "",
                detail: group.detail
            });
        });
    });

    formatted.sort((a, b) =>
        daysInOrder.indexOf(a.firstDay) - daysInOrder.indexOf(b.firstDay)
    );

    if (tarrif.maxPriceHoliday) {
        formatted.push({
            firstDay: "holiday",
            lastDay: "",
            detail: [{ string: "", price: tarrif.maxPriceHoliday }]
        });
    }

    return formatted;
};