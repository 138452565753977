import styles from './DetailZoneItem.module.scss';
import parkingIcon from '../../assets/images/parking.svg';
import streetIcon from '../../assets/images/street.svg';
import timeIcon from '../../assets/images/time.svg';
import pricingIcon from '../../assets/images/pricing.svg';
import {useTranslation} from "react-i18next";
import {formatTarrifPricing} from "../../services/tariffPricing";
import React, {useEffect, useState} from "react";

type Props = {
    type: string,
    value: any,
    subValue?: any,
    id?: any,
    onTogglePricingDetail?: (state: boolean) => void,
    pricingDetailState?: boolean
};

const DetailIcon = (type: string) => {
    if (type === "name") return parkingIcon;
    if (type === "street") return streetIcon;
    if (type === "time") return timeIcon;
    if (type === "pricing") return pricingIcon;
};

const DetailZoneItem: React.FC<Props> = ({...props}) => {
    const {t} = useTranslation();
    let pricing;
    if (props.type === "pricing") pricing = formatTarrifPricing(props.subValue);

    const [pricingDetail, setPricingDetail] = useState(props.pricingDetailState || false);

    useEffect(() => {
        setPricingDetail(false);
    }, [props.id]);

    useEffect(() => {
        if (props.pricingDetailState !== undefined) {
            setPricingDetail(props.pricingDetailState);
        }
    }, [props.pricingDetailState]);

    const handlePricingDetailToggle = () => {
        const newState = !pricingDetail;
        setPricingDetail(newState);
        if (props.onTogglePricingDetail) {
            props.onTogglePricingDetail(newState);
        }
    };

    return (
        <div className={styles.item}>
            <div className={styles.info}>
                <div className={styles.icon}>
                    <img src={DetailIcon(props.type)} alt={''} />
                </div>
                <div className={styles.title}>
                    <div className={styles.placeName}>
                        {props.value}
                        {(props.type === "time") ? " " + t("home.DetailZoneItemComponent.hour") : ""}
                        {(props.type === "pricing") ? " " + t("global.currency.czk") + " / " + t("global.hour") : ""}
                    </div>
                </div>
                <div className={styles.textEnd}>
                    {(props.type === "name") ? <span className={styles.textMuted}>{props.subValue ?? ''}</span> : ""}
                    {(props.type === "time") ? <span className={styles.textMuted}>{t("home.DetailZoneItemComponent.maxTime")}</span> : ""}
                    {(props.type === "pricing") ?
                        <span onClick={handlePricingDetailToggle} className={`${styles.textPricing} ${pricingDetail ? styles.arrowActive : ``}`}>
                            {t("home.DetailZoneItemComponent.pricing")}
                            <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8 9C7.71875 9 7.46875 8.90625 7.28125 8.71875L1.28125 2.71875C0.875 2.34375 0.875 1.6875 1.28125 1.3125C1.65625 0.90625 2.3125 0.90625 2.6875 1.3125L8 6.59375L13.2812 1.3125C13.6562 0.90625 14.3125 0.90625 14.6875 1.3125C15.0938 1.6875 15.0938 2.34375 14.6875 2.71875L8.6875 8.71875C8.5 8.90625 8.25 9 8 9Z" fill="#1D4F8B"/>
                                <path d="M8 9C7.71875 9 7.46875 8.90625 7.28125 8.71875L1.28125 2.71875C0.875 2.34375 0.875 1.6875 1.28125 1.3125C1.65625 0.90625 2.3125 0.90625 2.6875 1.3125L8 6.59375L13.2812 1.3125C13.6562 0.90625 14.3125 0.90625 14.6875 1.3125C15.0938 1.6875 15.0938 2.34375 14.6875 2.71875L8.6875 8.71875C8.5 8.90625 8.25 9 8 9Z" fill="black" fillOpacity="0.2"/>
                            </svg>
                        </span> : ""}
                </div>
                {(props.type === "pricing") ?
                    <div className={`${styles.pricingDetail} ${pricingDetail ? styles.pricingDetailActive : ``}`}>
                        {pricing.map((object, i2) =>
                            <div>
                                {object.detail.map((el, i) =>
                                    <table className={styles.table}>
                                        <tbody>
                                    <tr>
                                        <td className={styles.tableDay}>{i === 0 ? <span>{t(`global.daysShort.${object.firstDay}`)}{object.lastDay ? "-" + t(`global.daysShort.${object.lastDay}`) : ""}</span> : null}</td>
                                        <td className={styles.tableHours}>{el.string}</td>
                                        <td className={styles.tablePrice}>{object.firstDay === "holiday" ? t('global.max') : ""} {el.price} {t("global.currency.czk")} / {object.firstDay === "holiday" ? t("global.day") : t("global.hour")}</td>
                                    </tr>
                                        </tbody>
                                    </table>
                                )}
                            </div>
                        )}
                    </div>
                    : null
                }
            </div>
        </div>
    );
}

export default DetailZoneItem;