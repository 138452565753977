import {useIndexedDB} from "react-indexed-db-hook";
import useIntervalState from "./useIntervalState";

export default function useFavoriteZone() {
	const { getAll, add, deleteRecord, getByIndex: _getByIndex, clear: clearAll} = useIndexedDB("favoriteZones");
	const [zones, setZones] = useIntervalState((setState) => {
		getAll().then((zonesFromDB) => {
			setState(zonesFromDB);
		}).catch(e => setState([]));
	}, [])

	const getByIndex = (indexName: string, key: any) => {
		if (indexName === 'id') {
			key = parseInt(key);
		}
		return zones.find(zone => zone[indexName] === key);
	}

	const deleteByIndex = (indexName: string, key: any) => {
		if (indexName === 'id') {
			return deleteRecord(key)
		} else {
			return _getByIndex(indexName, key).then((entity) => deleteRecord(entity.id));
		}
	}

	return {zones, add, deleteRecord, getByIndex, getByIndexAsync: _getByIndex, deleteByIndex, clearAll};
}