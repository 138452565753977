import React, {useEffect, useState} from "react";
import styles from './MapZoom.module.scss';
import {map} from "../Map";
import View from "ol/View";
import * as mapSettings from "../../assets/map.json";
const {mapAnimationDuration} = mapSettings;

type Props = {
    action: "in" | "out";
    step: number;
};

const MapZoom: React.FC<Props> = ({ action, step }) => {
    const [view, setView] = useState<View | null>(null);

    useEffect(() => {
        setView(map.getView());
    }, []);

    const handleZoom = () => {
        if (view) {
            const currentZoom = view.getZoom();
            const newZoom = action === "in" ? currentZoom + step : currentZoom - step;
            view.animate({ zoom: newZoom, duration: mapAnimationDuration });
        }
    };

    return (
        <a className={styles.zoomButton} onClick={handleZoom}>
            {action === "in" ?
                <svg width="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        className={styles.changeColor}
                        d="M13.5 7C13.5 7.5625 13.0312 8.03125 12.5 8.03125H8V12.5312C8 13.0625 7.53125 13.5 7 13.5C6.4375 13.5 6 13.0625 6 12.5312V8.03125H1.5C0.9375 8.03125 0.5 7.5625 0.5 7C0.5 6.46875 0.9375 6.03125 1.5 6.03125H6V1.53125C6 0.96875 6.4375 0.5 7 0.5C7.53125 0.5 8 0.96875 8 1.53125V6.03125H12.5C13.0312 6 13.5 6.46875 13.5 7Z"
                        fill="#1D4F8B"/>
                    <path
                        className={styles.changeColor}
                        d="M13.5 7C13.5 7.5625 13.0312 8.03125 12.5 8.03125H8V12.5312C8 13.0625 7.53125 13.5 7 13.5C6.4375 13.5 6 13.0625 6 12.5312V8.03125H1.5C0.9375 8.03125 0.5 7.5625 0.5 7C0.5 6.46875 0.9375 6.03125 1.5 6.03125H6V1.53125C6 0.96875 6.4375 0.5 7 0.5C7.53125 0.5 8 0.96875 8 1.53125V6.03125H12.5C13.0312 6 13.5 6.46875 13.5 7Z"
                        fill="#1D4F8B"/>
                </svg>
                :
                <svg width="14" viewBox="0 0 14 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        className={styles.changeColor}
                        d="M12.5 2H1.5C0.9375 2 0.5 1.5625 0.5 1C0.5 0.46875 0.9375 0 1.5 0H12.5C13.0312 0 13.5 0.46875 13.5 1C13.5 1.5625 13.0312 2 12.5 2Z"
                        fill="#1D4F8B"/>
                    <path
                        className={styles.changeColor}
                        d="M12.5 2H1.5C0.9375 2 0.5 1.5625 0.5 1C0.5 0.46875 0.9375 0 1.5 0H12.5C13.0312 0 13.5 0.46875 13.5 1C13.5 1.5625 13.0312 2 12.5 2Z"
                        fill="#1D4F8B" />
                </svg>

            }
        </a>
    );
};

export default MapZoom;