import React, { PropsWithChildren, useEffect, useState, useMemo, useCallback, useRef } from "react";
import { useField, useFormikContext } from "formik";
import Select from "react-select";
import useVehiclesState from "../../../model/useVehiclesState";
import styles from './VehiclePicker.module.scss';
import SelectOptionVehicle from "../../SelectOption/SelectOptionVehicle";
import { colourStyles } from "../../form/Select/Select";
import { t } from "i18next";

type Props = {
	name: string;
	hasError: boolean;
} & PropsWithChildren & any;

const VehiclePicker: React.FC<Props> = ({ name, hasError }) => {
	const { setFieldValue, setFieldTouched, values, validateField } = useFormikContext();
	const [vehicles, setVehicles] = useState<any[]>([]);
	const [selectedOption, setSelectedOption] = useState<any>(null);
	const [customSpz, setCustomSpz] = useState<string>('');
	const { vehicles: _vehicles } = useVehiclesState();
	const [, meta] = useField(name);
	const customInputRef = useRef<HTMLInputElement | null>(null);

	const customStyles = {
		...colourStyles,

		control: (provided: any, state: any) => ({
			...colourStyles.control(provided, state),
			backgroundColor: selectedOption?.value === 'custom' ? "#F3F3F5" : state.isFocused ? "#fff" : '#F3F3F5',
		}),
	};

	useEffect(() => {
		if (JSON.stringify(_vehicles) != JSON.stringify(vehicles)) {
			setVehicles(_vehicles);
		}
	}, [_vehicles]);

	const getOptions = useMemo(() => {
		const options = vehicles.map((vehicle) => ({
			value: vehicle.spz,
			label: vehicle.name,
			isDisabled: false,
		}));

		options.push({
			value: 'custom',
			label: t('VehiclePickerComponent.newVehicle'),
			isDisabled: false,
		});

		return options;
	}, [vehicles, t]);

	useEffect(() => {
		const initialValue = values[name];
		const foundVehicle = vehicles.find((vehicle) => vehicle.spz === initialValue);

		if (foundVehicle) {
			setSelectedOption({ value: foundVehicle.spz, label: foundVehicle.name });
		} else if (initialValue) {
			setSelectedOption({ value: 'custom', label: t('VehiclePickerComponent.newVehicle') });
			setCustomSpz(initialValue);
		}

		console.log(selectedOption)
	}, [vehicles, name, values, t]);

	const handleChange = useCallback((newValue: any) => {
		setSelectedOption(newValue);
		if (newValue?.value !== 'custom') {
			setFieldValue(name, newValue?.value || '');
			setFieldTouched(name, true, false);
			validateField(name);
		} else {
			setFieldValue(name, customSpz);
			customInputRef.current?.focus();
			validateField(name);
		}
	}, [name, setFieldTouched, setFieldValue, customSpz, validateField]);

	const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.currentTarget.value;
		setCustomSpz(value);
		setFieldValue(name, value);
		if (value) {
			setFieldTouched(name, true, false);
		}
		validateField(name);
	}, [setFieldValue, setFieldTouched, name, validateField]);

	return (
		<div className={styles._container}>
			<Select
				options={getOptions}
				styles={customStyles}
				isSearchable={false}
				placeholder={t("buy.carSelectPlaceholder")}
				components={{
					IndicatorSeparator: () => null,
					Option: SelectOptionVehicle,
				}}
				value={selectedOption}
				name={name}
				onChange={handleChange}
			/>
			{selectedOption?.value === 'custom' && (
				<input
					ref={customInputRef}  // Attach ref to input
					className={styles.customSpz}
					value={customSpz}
					maxLength={10}
					onChange={handleInputChange}
					placeholder={t('VehiclePickerComponent.licencePlate')}
				/>
			)}
		</div>
	);
};

export default VehiclePicker;
