import {Feature, Map} from "ol";
import {transform, transformExtent} from "ol/proj";
import View from "ol/View";
import {defaults} from "ol/control";
import {defaults as interactionDefaults} from "ol/interaction";
import {MapboxVectorLayer} from 'ol-mapbox-style';
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import {Fill, Icon, Stroke, Style} from "ol/style";
import * as mapSettings from "../assets/map.json";
import {GeoJSON} from "ol/format";
import {Circle, Geometry} from "ol/geom";
import CircleStyle from "ol/style/Circle";

const {mapCenter, mapZoom, mapMaxExtent, minZoom, maxZoom} = mapSettings;

const layer = new MapboxVectorLayer({
    styleUrl: 'mapbox://styles/mapbox/streets-v12',
    accessToken: process.env.REACT_APP_MAP_API_KEY
});

const hexToRgb = (hex: string) =>
    hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
        ,(m, r, g, b) => '#' + r + r + g + g + b + b)
        .substring(1).match(/.{2}/g)
        .map(x => parseInt(x, 16))

export const styleElement = (color: string, selected: boolean | null, hover: boolean = false) => {
    const rgb = hexToRgb(color);
    const zoom = map.getView().getZoom();
    const dashLength = zoom * 0.6;
    const gapLength = zoom * 0.375;
    const fillOpacity = hover ? 0.5 : selected === false ? 0.3 : 0.8;
    const lineDash = hover || selected === false ? [dashLength, gapLength] : undefined;

    return new Style({
        stroke: new Stroke({
            color: color,
            width: hover ? 1.5 : 1,
            lineDash: lineDash
        }),
        fill: new Fill({
            color: `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${fillOpacity})`
        })
    });
};

export const invisibleStyle = new Style({
    fill: null,
    stroke: null
});

export const parkingZones = new VectorLayer({
    source: new VectorSource()
});

export const parkingMachines = new VectorLayer({
    source: new VectorSource()
});

export const myLocationMarker = new VectorLayer({
    source: new VectorSource()
});

export const myLocationMarkerStyleBackground = new Style({
    fill: new Fill({
        color: 'rgba(99, 147, 242, 0.2)'
    }),
    zIndex: 5
})

export const myLocationMarkerStyleCircle = new Style({
    stroke: new Stroke({
        color: "#ffffff",
        width: 2,
    }),
    fill: new Fill({
        color: '#6393F2'
    }),
    zIndex: 10
})

const maxExtent = transformExtent(mapMaxExtent, 'EPSG:4326', 'EPSG:3857')

export const map = new Map({
    target: "map",
    layers: [layer, parkingZones, parkingMachines, myLocationMarker],
    view: new View({
        center: transform(mapCenter, 'EPSG:4326', 'EPSG:3857'),
        zoom: mapZoom,
        extent: maxExtent,
        maxZoom: maxZoom
    }),
    controls: defaults({attribution: false, zoom: false}),
    interactions: interactionDefaults({
        pinchRotate: false,
        shiftDragZoom: false
    })
});

export const loadParkingZonesIntoMap = (data: any): Promise<void> => {

    return new Promise<void>((resolve, reject) => {
        try {
            data.forEach((zone) => {
                if (!parkingZones.getSource().getFeatureById(zone.sectionID)) {
                    let features = new GeoJSON().readFeatures(zone.sectionBorder, {
                        dataProjection: 'EPSG:4326',
                        featureProjection: 'EPSG:3857',
                    });

                    features.forEach((feature) => {
                        if (feature instanceof Feature) {
                            feature.setStyle(styleElement(zone.sectionCategoryColor, false));
                            feature.setId(zone.sectionID);
                            //console.log(zone);
                            feature.setProperties({
                                sectionCode: zone.sectionCode,
                                sectionCategoryColor: zone.sectionCategoryColor,
                                sectionCategoryName: zone.sectionCategoryName,
                                sectionCategoryID: zone.sectionCategoryID,
                                sectionID: zone.sectionID,
                                streetName: zone.streetName,
                                tariffInfo: zone.tariffInfo,
                                tariffCode: zone.tariffCode,
                                tariffID: zone.tariffID,
                                sectionBorder: zone.sectionBorder,
                                featureType: 'parkingZone'
                            });
                        }
                    });

                    parkingZones.getSource().addFeatures(features as Feature<Geometry>[]);
                }
            });

            resolve();
        } catch (error) {
            reject(error);
        }
    });
};

export const addParkingMachine = (geojson: any, color: string, properties: any) => {
    const format = new GeoJSON();
    const features = format.readFeatures(geojson, {
        featureProjection: 'EPSG:3857',
    });

    features.forEach((feature) => {
        if (feature instanceof Feature) {
            const iconStyle = new Style({
                image: new Icon({
                    src: "/images/parking-machine.svg",
                    scale: 0.019,
                    anchor: [0.5, 0.5],
                }),
            });

            const circleStyle = new Style({
                image: new CircleStyle({
                    radius: 15,
                    fill: new Fill({
                        color: color,
                    }),
                    stroke: new Stroke({
                        color: 'rgb(255, 255, 255)',
                        width: 2,
                    })
                }),
            });

            feature.setStyle([circleStyle, iconStyle]);
            feature.setProperties({
                ...properties,
                featureType: 'parkingMachine'
            });
            feature.setId(properties.parkMachineID);

            parkingMachines.getSource()?.addFeature(feature as Feature<Geometry>);
        }
    });
};

export const visibleZonesInViewport = () => {
    let features:Array<Object> = [];
    const extent = map.getView().calculateExtent(map.getSize());
    parkingZones.getSource().forEachFeatureInExtent(extent, function (feature) {
        let properties = feature.getProperties();
        if(properties.visible === undefined || properties.visible === true) features.push(properties);
    });
    return features;
}

export const checkMapWidthLimit = () => {
    const extent = map.getView().calculateExtent(map.getSize());
    const extentInDegrees = transformExtent(extent, 'EPSG:3857', 'EPSG:4326');
    const widthInDegrees = extentInDegrees[2] - extentInDegrees[0];
    const limit = 0.05;
    return widthInDegrees <= limit;
}

export const mapDebounce = <F extends (...args: any[]) => void>(func: F, wait: number): ((...args: Parameters<F>) => void) => {
    let timeoutId: ReturnType<typeof setTimeout> | null = null;

    return (...args: Parameters<F>) => {
        const later = () => {
            timeoutId = null;
            func(...args);
        };

        if (timeoutId !== null) {
            clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(later, wait);
    };
};