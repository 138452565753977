import React from "react";
import styles from './Settings.module.scss';
import {useTranslation} from "react-i18next";


const Gdpr = () => {

    const {t} = useTranslation();

    return (
        <div className={styles._container}>
            <div className={styles.wrapper} style={{width: 1240}}>
                <h1>{t("gdpr.title")}</h1>
                <div>{t("gdpr.content")}</div>
                <div dangerouslySetInnerHTML={{__html: t("gdpr.content2")}}></div>
            </div>
        </div>
    );
}

export default Gdpr;