import React from 'react';
import { components } from 'react-select';

const SelectOptionVehicle = (props) => {
    return (
        <components.Option {...props}>
            <span style={{
                borderBottom: props.isSelected ? '2px solid #C82128' : '1px dashed #888B8D',
                fontWeight: props.isSelected || props.isFocused ? 'bold' : 'normal',
                paddingBottom: '12px',
                display: 'block'
            }}>
                { props.data.value === "custom" ? <span style={{display: "block", textAlign: "center"}}>{props.data.label}</span> : <b>{props.data.label}</b>}
                { props.data.value === "custom" ? "" : " - " + props.data.value }
            </span>
        </components.Option>
    );
};

export default SelectOptionVehicle;
