import styles from './LanguageSwitcher.module.scss';
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import csFlag from "../../assets/images/cs.svg";
import enFlag from "../../assets/images/en.svg";
import Cookies from "js-cookie";

interface LanguageSwitcherProps {
    imageHeight?: number;
    className?: string;
}

const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({
                                                               imageHeight = 14,
                                                               className
                                                           }) => {
    const {t} = useTranslation();

    const changeLang = (e: string) => {
        Cookies.set('lang', e);
        i18n.changeLanguage(e);
    };

    const lang = i18n.language;

    return (
        <a
            className={`${styles.languageSwitcher} ${className || ''}`}
            onClick={() => changeLang(lang === "cs" ? "en" : "cs")}
        >
            <img
                height={imageHeight}
                src={lang === "cs" ? enFlag : csFlag}
                alt={lang === "cs" ? t("home.LanguageSwitcherComponent.en") : t("home.LanguageSwitcherComponent.cs")}
            />
        </a>
    );
};

export default LanguageSwitcher;