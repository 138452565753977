import { useState, useEffect, useCallback } from 'react';

/**
 * React hook that syncs state with localStorage
 *
 * @param key - The key under which the value will be stored in localStorage
 * @param defaultValue - Default value or function that returns the default value,
 *                      used when no value exists in localStorage
 * @returns A tuple containing the current state and a setter function
 *
 * @example
 * function Component() {
 *   const [count, setCount] = useLocalStorageState('count', 0);
 *   const [user, setUser] = useLocalStorageState('user', { name: 'John' });
 *
 *   return (
 *     <button onClick={() => setCount(count + 1)}>
 *       Count is {count}
 *     </button>
 *   );
 * }
 */
function useLocalStorageState<T>(
    key: string,
    defaultValue: T | (() => T)
): [T, (value: T | ((prevValue: T) => T)) => void] {
    // Initialize state with a function to handle localStorage reading
    const [state, setState] = useState<T>(() => {
        try {
            // Try to get the value from localStorage first
            const item = localStorage.getItem(key);

            if (item !== null) {
                return JSON.parse(item);
            }

            // If no value in localStorage, use the defaultValue
            if (defaultValue instanceof Function) {
                return defaultValue();
            }

            return defaultValue;
        } catch (error) {
            console.error(`Error reading ${key} from localStorage:`, error);
            // Fallback to defaultValue if there's an error
            return defaultValue instanceof Function ? defaultValue() : defaultValue;
        }
    });

    // Sync state changes to localStorage
    useEffect(() => {
        try {
            localStorage.setItem(key, JSON.stringify(state));
        } catch (error) {
            console.error(`Error writing ${key} to localStorage:`, error);
        }
    }, [key, state]);

    // Wrapper for setState that handles errors
    const setStateAndStorage = useCallback(
        (value: T | ((prevValue: T) => T)) => {
            try {
                setState(value);
            } catch (error) {
                console.error('Error updating state:', error);
            }
        },
        []
    );

    return [state, setStateAndStorage];
}

export default useLocalStorageState;