import styles from './MapResultItem.module.scss';
import Icon from "../Icon";
import {useEffect, useState} from "react";
import useFavoriteZone from "../../model/useFavoriteZone";

type Props = {
	wrapperClassName?: string
	placeName: string,
	parkingInfo: string
	sectionId: string,
	isCurrent?: boolean,
	section: any,
	onClick?: () => void,
	favorites?: boolean
};

const MapResultItem: React.FCC<Props> = ({ isCurrent = false, ...props}) => {

	const { zones, add, deleteByIndex } = useFavoriteZone();

	useEffect(() => {
		const zone = zones.find(zone => zone.id === props.sectionId);
		setIsFavorite(zone);
	}, [zones]);

	const [isFavorite, setIsFavorite] = useState<boolean>(false);

	const handleFavorite = () => {
		if (!isFavorite) {
			add({"id": props.sectionId, "json": JSON.stringify(props.section)});
		} else {
			deleteByIndex("id", props.sectionId);
		}
	}

	const showFavorites = props.favorites ?? true;

	if (isFavorite && !showFavorites) {
		return null;
	}

	return (
		<div className={`${styles.item} ${props.wrapperClassName} ${isCurrent ? styles.current : ''}`} onClick={() => props.onClick && props.onClick()}>
			<div className={styles.info}>
				<div className={styles.title}>
					<div className={styles.placeName}>
						{props.placeName}
					</div>
					<div className={styles.parkingInfo}>
						{props.parkingInfo}
					</div>
				</div>
				<div className={styles.actions}>
					<span onClick={handleFavorite}>{isFavorite ? <Icon.StarActiveStroke size={17} color={isFavorite ? "#FFDF21" : "#C5C4C6"}/> : <Icon.Star size={15} color={isFavorite ? "#FFDF21" : "#C5C4C6"}/>}</span>
					<Icon.CaretRight size={15} color={"#C5C4C6"}/>
				</div>
			</div>
		</div>
	);
}

export default MapResultItem;