import React from "react";
import styles from './RemoveCard.module.scss';
import Icon from "../../components/Icon";
import {useTranslation} from "react-i18next";

type Props = {
	title: string,
	type: 'vehicle'|'card'|'email',
	onCancel: () => void,
	onSubmit: () => void
}

const RemoveDialog = ({...props}: Props) => {
	const {t} = useTranslation();

	const trans = {
		vehicle: t("settings.removeDialog.vehicle"),
		card: t("settings.removeDialog.card"),
		email: t("settings.removeDialog.email")
	}

	const submitButtonTrans = {
		vehicle: t("settings.removeDialog.delVehicle"),
		card: t("settings.removeDialog.delCard"),
		email: t("settings.removeDialog.delEmail")
	}

	return (
		<div className={styles.wrapper}>
			<Icon.TrashXMark className={styles.trashIcon} size={48} />
			<div className={styles.title}>
				<h2>{props.title}</h2>
				<div>{trans[props.type] ?? ''}</div>
			</div>
			<div className={styles.actions}>
				<button className={`btn btn-primary`} onClick={() => props.onCancel()}>
					<Icon.Revert size={12} color={"#FFFFFF"} />
					{t("settings.removeDialog.goBack")}
				</button>
				<button className={`btn btn-outline-danger`} onClick={() => props.onSubmit()}>
					<Icon.Remove size={12} />
					{submitButtonTrans[props.type] ?? ''}
				</button>
			</div>
		</div>
	);
}

export default RemoveDialog;