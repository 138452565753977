import React, {useEffect, useState} from "react";
import savedStyles from '../Settings.module.scss';
import {Link, useNavigate} from "react-router-dom";
import Icon from "../../components/Icon";
import styles from "../Settings.module.scss";
import {Field, Form, Formik, useFormikContext} from "formik";
import * as Yup from 'yup';  // Import Yup
import {useTranslation} from "react-i18next";
import useEmailsState from "../../model/useEmailsState";

const NewEmail = () => {
	interface FormValues {
		[key: string]: string;
	}

	const { add } = useEmailsState();
	const navigate = useNavigate();

	const {t} = useTranslation();

	const initialValues = {
		email: '',
	};

	const validationSchema = Yup.object().shape({
		email: Yup.string().matches(/^[^@\s]+@[^@\s]+\.[a-zA-Z]{2,}$/).required(),
	});

	const [disabledButton, setDisabledButton] = useState(true);

	const FormikUseEffect = () => {
		const {values, errors, touched} = useFormikContext<FormValues>();
		useEffect(() => {
			if(values.email && !errors.email && touched.email) {
				setDisabledButton(false);
			} else {
				setDisabledButton(true);
			}
		}, [values, errors, touched]);
		return null;
	}


	const handleSubmit = (values) => {
		if(values.email) {
			add(values);
			navigate('/settings');
		}
	}

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={handleSubmit}
			validationSchema={validationSchema}
		>
			{({ errors, touched, setFieldTouched }) => (
				<Form className={savedStyles._container}>
					<FormikUseEffect />
					<div className={savedStyles.wrapper}>
						<Link to={'/settings'} className={`d-flex-center gap-3 ${savedStyles.backLink}`}>
							<Icon.LeftChevron size={12} />
							{t("settings.newEmail.back")}
						</Link>
						<h1>{t("settings.newEmail.title")}</h1>
						<div className={styles.itemGroups}>
							<div className={styles.itemGroup}>
								<div className={`${styles.inputItem} ${errors.email && touched.email ? styles.inputError : ''}`}>
									<div className={styles.labelIcon}>
										<Icon.Email size={22} color={errors.email && touched.email ? "#CC1818" : "#1D4F8B"}/>
									</div>
									<div className={styles.cardContent}>
										<div>{t("settings.newEmail.email")}</div>
									</div>
									<div className={styles.input}>
										<Field
											name={'email'}
											onInput={() => setFieldTouched('email', true, true)}
											type={'email'}
											placeholder={t("settings.newEmail.emailPlaceholder")}
										/>
									</div>
								</div>
							</div>
							{(errors.email && touched.email) &&
								<div className={styles.inputErrorColor}>
									<p>{t('settings.newEmail.error')}</p>
								</div>
							}
						</div>
						<button disabled={disabledButton} type={'submit'} className={`btn btn-primary ${savedStyles.submitButton}`}>
							<Icon.Check size={16} color={"#FFFFFF"} />
							{t("settings.newEmail.save")}
						</button>
					</div>
				</Form>
			)}
		</Formik>
	);
}

export default NewEmail;
